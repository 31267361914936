<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <v-row class="align-center no-gutters">
      <v-col class="col-auto headline pt-1">
        MuniX
      </v-col>
      <v-col class="col-auto pl-3">
        <v-img
          contain
          :src="require('@/assets/logo.svg')"
          transition="scale-transition"
        />
      </v-col>
      <v-spacer/>
      <v-col class="col-auto text-right headline pointer">
        <span class="fas fa-door-open mr-1"/>
        Login
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Header'
});
</script>