










import Vue from 'vue';
import mheader from '@/components/header/Header.vue'

export default Vue.extend({
  name: 'App',
  components: {mheader}
});
